import React, { Component } from 'react';
import Carousel from 'nuka-carousel';
import TweenOne from 'rc-tween-one';
import './../../assets/styles/OthersBooks.css';
import bgOtherbooks from './../../assets/images/book/yellow_background.png';

// TODO: Transformar em compoennte funcional

class OthersBooks extends Component {
	state = {
		SLIDES: []
	};

	componentDidMount() {
		return this.loadBooks();
	}

	loadBooks() {
		let curentBooks = [];
		this.props.payload.listData.map((item) => {
			// aqui neste momento é possível eliminar
			// algum livro da lista se necessário
			curentBooks.push(
				<div key={item.bookId}>
					<div
						id={item.bookId}
						onClick={(e) => {
							this.props.onSlideChange(e.target.id);
							setTimeout(() => {
								const mainTitleContainer = document.getElementById('main_title_container');
								mainTitleContainer &&
									mainTitleContainer.scrollIntoView({
										behavior: 'smooth'
									});
							}, 500);
						}}
						className="others_books_slide"
						style={{ backgroundImage: `url(${item.bookCover})` || `url(${bgOtherbooks})` }}
						onMouseDown={(e) =>
							document.getElementById(e.target.id).classList.toggle('others_books_shadow')}
						onMouseUp={(e) => document.getElementById(e.target.id).classList.toggle('others_books_shadow')}
					/>
					<p id="month">{item.bookMonthName}</p>
					<p id="availability">Baixe até</p>
					<p id="availabilityDate">{item.bookAvailability}</p>
				</div>
			);
		});
		this.setState({
			SLIDES: curentBooks.reverse()
		});
	}

	render() {
		const isSurprise = false;
		const totalSlides = this.state.SLIDES.length;
		const slidesToShow = 1; //screenWidth > 700 ? 3 : 1;
		const slideIndex = totalSlides < 2 ? 0 : totalSlides - 1 - 1;
		return (
			<div id="others_books" className="others_books_container">
				<p className='also_read'>Leia também:</p>

				<TweenOne
					id="others_books"
					className={`others_books_slide_container ${isSurprise ? 'hide' : ''}`}
					paused={isSurprise}
					animation={{
						opacity: 1
					}}
				>
					<Carousel
						withoutControls={false}
						slidesToShow={slidesToShow}
						slideIndex={slideIndex}
						renderBottomLeftControls={({ previousSlide }) => (
							<a className="others_books_nav_buttons" onClick={previousSlide}>
								{'<'}
							</a>
						)}
						renderBottomRightControls={({ nextSlide }) => (
							<a className="others_books_nav_buttons" onClick={nextSlide}>
								{'>'}
							</a>
						)}
					>
						{this.state.SLIDES}
					</Carousel>
				</TweenOne>
			</div>
		);
	}
}

export default OthersBooks;
