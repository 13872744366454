import React from "react";
import pngLoader from "./../../assets/images/anim_404.png";
import "./../../assets/styles/Error404.css";
import Footer from "../footer/index";
import Logo from "../logo/index";

const Error404 = props => {
  if (props.appState !== "error404") {
    return null;
  }

  let locationUrl = window.location.host;
  if (locationUrl.indexOf("http") < 0) {
    // for 'localhost' case example
    locationUrl = `http://${window.location.host}`;
  }

  setTimeout(() => {
    window.location.replace(locationUrl);
  }, 2500);

  return (
    <div className="error404_container">
      <Logo />
      <img style={{ width: "65%" }} src={pngLoader} alt={"Reiniciando..."} />
      <Footer />
    </div>
  );
};

export default Error404;
