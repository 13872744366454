import React, { useState, useEffect, useRef } from "react";
import { default_server } from "./../../services/api";
import { ParallaxProvider } from 'react-scroll-parallax';
import "./../../assets/styles/Section.css";

const Section = props => {

  const axios = require("axios");
  const viewportHeight = window.innerHeight;
  const ref = useRef(null);
  const [appState, updateAppState] = useState(props.parent.appState);
  const [payload, updatePayload] = useState(props.parent.payload);
  const [currentBookID, setCurrentSlideId] = useState(0);


  // VARRE TODOS OS FILHOS PARA INJETAR PROPS
  // AGORA COM WRAPPERS, VAMOS ENTRAR NOS FILHOS DOS FILHOS
  const childrens = props.children.map((chdrn, idx) => {
    const IS_PARALLAX_ELEMENT = chdrn.props.hasOwnProperty('type');
    if (appState === 'login') {
      return (
        React.cloneElement(chdrn, {
          key: `${idx}`,
          payload: payload,
          appState: appState,
          currentBookID: currentBookID,
          onSlideChange: slideIdx => setCurrentSlideId(slideIdx)
        })
      )
    }
    else if (appState === 'landingpage') {
      if (IS_PARALLAX_ELEMENT) {
        return chdrn;
      }
      else {
        return <div id={`section${idx+1}`} key={`section${idx+1}`}>{
          chdrn.props.children.map((grandchdrn, idy) => {
            if (grandchdrn.type === 'div' && grandchdrn.props.className === 'other-books-section') {
              const child = grandchdrn.props.children.map((c, idc) => (
                React.cloneElement(c, {
                  key: `${idx}${idy}${idc}`,
                  payload: payload,
                  appState: appState,
                  currentBookID: currentBookID,
                  onSlideChange: slideIdx => setCurrentSlideId(slideIdx)
                })
              ))

              return React.cloneElement(grandchdrn, {
                key: `${idx}${idy}`,
                payload: payload,
                appState: appState,
                currentBookID: currentBookID,
                onSlideChange: slideIdx => setCurrentSlideId(slideIdx),
                children: child
              })
            }

            if (grandchdrn.type !== 'div') {
              return (
                React.cloneElement(grandchdrn, {
                  key: `${idx}${idy}`,
                  payload: payload,
                  appState: appState,
                  currentBookID: currentBookID,
                  onSlideChange: slideIdx => setCurrentSlideId(slideIdx)
                })
              )
            }
          })
        }</div>
      }
    }
  });


  useEffect(() => {

    if (currentBookID === 0) {
      return;
    }

    for (let i = 0; i < payload.listData.length; i++) {
      const element = payload.listData[i];
      if (element.bookId === currentBookID) {
        if ("bookDownloadFile" in element) {
          return;
        }
      }
    }

    updateAppState('loading-book');

    // axios.post('http://www.mocky.io/v2/5e3b77d63000007700214595',
    axios
      .post(
        `${default_server}/getlivro/`,
        {
          token: payload.token,
          bookId: `${currentBookID}`
        },
        {
          timeout: 20000,
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      .then(success => {
        const newData = success.data;
        const updatedList = payload.listData.map((item, index) => {
          if (item.bookId === currentBookID) {
            return {
              ...newData,
              ...item
            };
          } else {
            return {
              ...item
            };
          }
        });
        updatePayload({
          ...payload,
          listData: updatedList
        });
        updateAppState('landingpage');
      })
      .catch(error => {
        console.log(error);
      });
  }, [axios, currentBookID, payload]);
  



  return (
    <ParallaxProvider ref={ref}>
      {childrens}
    </ParallaxProvider>
  );

  
};

export default Section;
